import React from "react"

const thanksContent = () => {
  return (
    <div className="thanks pageContent">
      <h1>Thank You For Your Inquiry</h1>
      <p>
        You did your part in making me feel wanted...So you can expect a prompt
        reply
      </p>
      <p>
        After verifying your information, we will get in touch and marvelous
        things may start to happen.
      </p>
    </div>
  )
}

export default thanksContent
